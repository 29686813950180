/* CSS Document */


/* 
*
*
[Table of contents]
*
*
*

  color / $white
  dropdown / .dropdown-custom.btn
  navbar / .navbar
  breadcumb / .breadcumb
  home / .subheading
  icon scroll / .icon-scroll-wraper
  footer / footer
  portofolio / AppContainer
  contact / .form-side
  news / .post-content
  create file / .d-create-file
  activity / .filter__l
  feature box / .feature-box .inner
  column nft / .nft_coll
  countdown / .de_countdown
  author list / .author_list
  icon box / .icon-box 
  carousel / .d-carousel
  filter / .items_filter
  colection / #profile_banner 
  item details / .item_info
  wallet / .box-url 
  login / .field-set label
  all icon / .demo-icon-wrap-s2 
  element / .card-header
  media all / @media only screen and (max-width: 1199px)

*/

/*color*/
$white: #fff;
$gray: #bbb;
$black: #111;
$general: #a2a2a2;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #0d6efd;
$color_more: #0d6efd;

.greyscheme .col-white {
  color: $white;
}

/*general*/
.greyscheme {
  background: #212428;
}

.greyscheme .br {
  display: block;
  white-space: pre-wrap;
}

.greyscheme strong {
  font-weight: bold;
}

.greyscheme section {
  padding: 90px 0;
  background: #212428;

  &.no-top {
    padding-top: 0 !important;
  }

  &.no-bottom {
    padding-bottom: 0 !important;
  }

  .small-border {
    display: block;
    width: 50px;
    height: 2px;
    background: $color;
    border-left: none;
    border-left-color: currentcolor;
    border-right: none;
    border-right-color: currentcolor;
    margin: 18px auto 30px;
  }

  &.bg-gray {
    background: rgb(247, 244, 253);
  }

  &.seller_section {
    padding-top: 0;
    // background-image: url(https://templatekit.jegtheme.com/creative1/wp-content/uploads/sites/43/2020/11/7Artboard-90.jpg) !important;
    // background-attachment: fixed !important;
    // background-size: cover !important;
  }
}

.greyscheme .container {
  padding-left: 30px;
  padding-right: 30px;
}

.greyscheme .wraper {
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;

  .wraperitem {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.greyscheme .m-2-hor {
  padding: 0 7%;
  max-width: 1500px;
  margin: 0 auto !important;
}

.greyscheme .m-10-hor {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  max-width: 1500px;
  margin: 0 auto;
}

.greyscheme .align-items-center {
  align-items: center !important;
}

.greyscheme .text-uppercase {
  text-transform: uppercase !important;
}

.greyscheme .white {
  color: $black;
  background: $white;
}

.greyscheme .black_more {
  background: $black_more;
}

.greyscheme .color {
  color: $color;
}

.greyscheme .btn {
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  color: $white;
  background: none;
  border: 2px solid $color;
  border-radius: 0;
  padding: 10px 44px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    color: $white;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen .6s alternate;
      animation: sheen .6s alternate;
    }
  }
}

.greyscheme .shine {
  content: '';
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: -50px;
  background: -webkit-linear-gradient(top, transparent, rgba(255, 255, 255, 0.2) 5%, transparent);
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.2) 5%, transparent);
  -webkit-transform: rotateZ(60deg) translate(-1em, 5.5em);
  transform: rotateZ(60deg) translate(-1em, 5.5em);
}

// .accordion{
//   width:206% !important;
//   display:flex;
//   flex-wrap: wrap;
//   position:relative;
//   justify-content:center ;
//   bottom:0rem;
//   box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 0px;
//   cursor: pointer;
//   padding: 10px 20px;
//   margin: 0;
//   margin-top: 25px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: rgba(255, 255, 255, 0.1);
//   border-image: initial;
//   border-radius: 6px;
//   background: #181b25;
//   transition: all 0.7s ease 0s;
//   z-index: 999;
// }
.accordion {
  .accordion-item{
    border-radius: 50px !important;
    overflow: hidden;
    margin-top: 20px;
    border: 1px solid #dee2e6 !important;
  }

  .accordion-button {
    box-shadow: none !important;
    font-size: 20px;
  }

  .accordion-button::after {
    content: '\005E';
    background-image: none;
    font-size: 70px;
    transform: var(--bs-accordion-btn-icon-transform) !important;
    font-weight: 200;
  }

  .accordion-button:not(.collapsed)::after {
    content: '\005E';
    background-image: none;
    font-size: 70px;
    transform: none !important;
    margin: 10px 10px 0px auto;
  }

  .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #f20d6b !important;
  }
}

.accordion_hide {
  display: none !important;
}

.accordion_logo {
  width: 100%;
  height: 7.2rem !important;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 5px;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.logoWidth {
  width: 400px;
  margin: 10px;
  margin-bottom: 20px;
  position: relative;
  transition: all 200ms ease-in;
}

@-webkit-keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

@keyframes sheen {
  0% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, 6em);
    transform: rotateZ(60deg) translate(0, 6em);
  }

  90% {
    opacity: 1;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateZ(60deg) translate(0, -12em);
    transform: rotateZ(60deg) translate(0, -12em);
  }
}

.greyscheme .text-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background: linear-gradient(to bottom, $color, $color_more);
}

.greyscheme .spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.greyscheme .de-flex {
  display: flex;
  justify-content: space-between;
}

.greyscheme .de-flex>.de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.greyscheme hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/**** heading ***/
.greyscheme h1,
.greyscheme h2,
.greyscheme h4,
.greyscheme h3,
.greyscheme h5,
.greyscheme h6,
.greyscheme .h1_big,
.greyscheme .h1,
.greyscheme .h2,
.greyscheme .h3,
.greyscheme .h4,
.greyscheme .h5,
.greyscheme .h6 {
  margin-top: 0;
  font-weight: 700;
  color: $white;
}

.greyscheme h1,
.greyscheme .h1 {
  font-size: 50px;
  margin-bottom: 20px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

.greyscheme h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.greyscheme h1.s1 {
  letter-spacing: 30px;
  font-size: 26px;
}

.greyscheme h1 .small-border {
  margin-top: 30px;
}

.greyscheme h1.big,
.greyscheme .h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

.greyscheme h1.very-big {
  font-size: 120px;
  letter-spacing: -5px;
}

.greyscheme h1.ultra-big {
  font-size: 140px;
  line-height: 120px;
  letter-spacing: -6px;
  font-weight: 700;
  margin-bottom: 0;
}

.greyscheme h1.ultra-big span {
  display: inline-block;
}

.greyscheme h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

.greyscheme h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.greyscheme .h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

.greyscheme h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

.greyscheme h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 46px;
}

.greyscheme h2.style-2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.greyscheme h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

.greyscheme h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-weight: 500;
}

.greyscheme h2.s1,
.h2_s1 {
  font-size: 24px;
}

.greyscheme h2.deco-text span {
  font-family: "Parisienne";
  display: block;
  line-height: .85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.greyscheme h2.deco-text .md {
  font-size: 80px;
}

.greyscheme h2.deco-text .lg {
  font-size: 120px;
}

.greyscheme h2.deco-text .xl {
  font-size: 150px;
}

.greyscheme h3 {
  font-size: 22px;
  margin-bottom: 25px;
}

.greyscheme h4 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

.greyscheme h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

.greyscheme .subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.greyscheme .subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.greyscheme .subtitle.s2 i {
  margin-right: 10px;
}

.greyscheme .subtitle.s2 span:first-child {
  padding-left: 0;
}

.greyscheme h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

.greyscheme h4.s2 {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 200;
  line-height: 1.8em;
}

.greyscheme h4.s3 {
  font-family: "Parisienne";
  font-size: 60px;
  font-weight: lighter;
}

.greyscheme h4.s3 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 15px;
}

.greyscheme .call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

.greyscheme h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

.greyscheme h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

.greyscheme h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

.greyscheme h1.hs1 {
  font-family: "Parisienne";
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.greyscheme h1.hs2 {
  font-family: "Parisienne";
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

.greyscheme h2.subtitle {
  margin-top: 0;
}

.greyscheme h2.name {
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

.greyscheme h2.name span {
  display: block;
  font-size: 32px;
}

.greyscheme h2.name-s1 {
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

.greyscheme h2.name-s1 span {
  display: block;
  font-size: 32px;
}

.greyscheme h2.hw {
  display: block;
  font-family: "Parisienne";
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

.greyscheme h2.deco {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.greyscheme h2.deco span {
  display: inline-block;
  position: relative;
}

.greyscheme h2.deco span:before,
.greyscheme h2.deco span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

.greyscheme h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

.greyscheme h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

.greyscheme h2.hs1 {
  font-size: 40px;
}

.greyscheme h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

.greyscheme h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  font-family: "Miama";
  margin: 0 15px 0 10px;
  font-weight: normal;
}

.greyscheme h2 .de_light .text-light h2 {
  color: #fff;
}

.greyscheme .text-light h2.deco span:before,
.greyscheme .text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, .5);
}

.greyscheme h2.s2 {
  font-weight: 400;
}

.greyscheme h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.greyscheme h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

.greyscheme h4.style-2 {
  font-size: 18px;
}

.greyscheme h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.greyscheme h5.s2 {
  letter-spacing: 1px;
  font-size: 14px;
}

.greyscheme h5 {
  font-size: 18px;
}

.greyscheme h4.s1 {
  font-size: 12px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.greyscheme p.lead {
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 0;
  font-weight: 400;
}

.greyscheme .btn-main {
  display: block;
  width: max-content;
  text-align: center;
  color: #fff !important;
  background: #0d6efd;
  border-radius: 30px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 10px 40px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 2px 2px 15px 0px #0d6efd;
    transition: all 0.3s ease;
  }

  &.inline {
    display: inline-block;
    margin-right: 15px;

    &.white {
      color: $color_more !important;
      background: $white;
    }
  }
}

.greyscheme .imgslickz {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.greyscheme .centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*** slider ***/
.greyscheme .jumbomain {
  position: relative;
  padding: 0;
  margin: 0;
}

.greyscheme .slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .previousButton,
  .nextButton {
    opacity: 0;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  &:hover {

    .previousButton,
    .nextButton {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
}

.greyscheme .slide {
  background-size: cover !important;
}

.greyscheme .slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
  bottom: 0;
  left: 0;
}

.greyscheme .previousButton,
.greyscheme .nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  text-align: center;

  svg {
    display: none;
  }
}

.greyscheme .previousButton::before,
.greyscheme .nextButton::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  opacity: .8;
}

.greyscheme .previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.greyscheme .previousButton {
  left: 4%;
}

.greyscheme .nextButton {
  right: 4%;
}

.greyscheme .slider-content {
  text-align: left;
}

.greyscheme .slider-content .inner {
  padding: 0 12%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.greyscheme .slider-content .inner button {
  font-weight: 500;
  position: relative;
  overflow: hidden;
  font-size: 11pt;
  letter-spacing: 1px;
  color: $white;
  border: 2px solid $color;
  background: none;
  border-radius: 0;
  padding: 0 44px;
  height: 45px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  z-index: 0;

  span {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    width: 180%;
    height: 180%;
    top: -100%;
    left: -100%;
    position: absolute;
    background-image: linear-gradient(to left, $color, $color_more);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
  }

  &:hover::before {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
  }

  &:hover {
    .shine {
      -webkit-animation: sheen .6s alternate;
      animation: sheen .6s alternate;
    }
  }
}

.greyscheme .slider-content .inner h1 {
  font-weight: 600;
  max-width: 840px;
  color: #fff;
  font-size: 53px;
}

.greyscheme .slider-content .inner p {
  color: #fff;
  font-size: 40px;
  line-height: 1.3;
  max-width: 640px;
  margin-bottom: 30px;
}

.greyscheme .slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.greyscheme .slider-content section span {
  color: #fff;
}

.greyscheme .slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.greyscheme .slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.greyscheme .slider-content section span strong {
  color: #fff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {

  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {

  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .accordion {
    width: 100% !important;
  }

  .slider-content .inner h1 {
    font-size: 32px;
  }

  .slider-content .inner p {
    font-size: 21px;
  }
}

.greyscheme .slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.greyscheme .slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.greyscheme .slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.greyscheme .slide section * {
  transition: all 0.3s ease;
}

.greyscheme .slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.greyscheme .slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.greyscheme .slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: .9s;
  transition-delay: .9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.slide.animateOut p {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

/*** breadcumb ***/
.greyscheme .breadcumb {
  border-radius: 0;
  margin: 0;
  padding: 0 0;
  background-size: cover;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, .1);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, $black, rgba(0, 0, 0, .1));
    opacity: .5;
  }

  &.no-bg::before {
    content: unset;
  }

  .mainbreadcumb {
    padding: 250px 0 110px;
    backdrop-filter: contrast(120%) hue-rotate(310deg) grayscale(70%);

    h1 {
      margin-bottom: 0;
      color: $white;
    }

    p {
      color: $white;
      margin-top: 15px;
    }

    .list {
      position: relative;
      bottom: -20px;
      display: flex;
      justify-content: right;
      font-size: .86rem;
      font-weight: 400;
      letter-spacing: 4px;

      .dash {
        padding: 0 10px;
      }

      .link {
        color: $white;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .homebread {
    width: 100%;
    padding: 180px 0 140px;

    .heading {
      line-height: 1.3;
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .subheading {
      font-size: 2.1rem;
      margin-bottom: 0;
    }

    .content {
      max-width: 500px;

      margin-bottom: 0;
      margin-bottom: 30px;
    }
  }

  &.h-vh {
    // height: 100vh;
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
  }
}

/* home */
.greyscheme .subheading {
  font-size: 1.3rem;
  margin-bottom: 15px;
}

.greyscheme .heading {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.content {
  margin-bottom: .5rem;
}

.greyscheme .col-feature {
  display: block;

  .sub-color {
    color: $color;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px 0;
  }

  .content {}
}

.greyscheme .border-radius {
  border-radius: 0px;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
    transition: all 0.3s ease;
  }
}

/* icon scroll */
.greyscheme .icon-scroll-wraper {
  position: absolute;
  width: 100%;
  bottom: 5vh;
  left: 0;
}

.greyscheme .icon-scroll {
  position: relative;
  margin: 0 auto;
}

.greyscheme .icon-scroll:before {
  position: absolute;
}

.greyscheme .icon-scroll {
  width: 26px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .3);
  z-index: 1;
  border-radius: 25px;
  height: 38px;
}

.greyscheme .icon-scroll:before {
  content: '';
  width: 3px;
  height: 6px;
  background: rgba(255, 255, 255, .6);
  left: 12px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.greyscheme .features {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px;
  overflow: hidden;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    text-align: center;

    .heading {
      color: $color;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 10px;
    }

    .con-text {
      font-size: .9rem;
    }

    .link {
      width: max-content;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      font-size: 11pt;
      letter-spacing: 1px;
      color: #fff;
      border: 2px solid $color;
      background: none;
      border-radius: 0;
      padding: 0 44px;
      height: 45px;
      line-height: 40px;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: $color;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all 0.3s ease;

        .shine {
          -webkit-animation: sheen .6s alternate;
          animation: sheen .6s alternate;
        }
      }
    }
  }
}


/*** footer ***/
.greyscheme footer {
  padding: 80px 0 0 0;
  background: rgba(255, 255, 255, .025) !important;

  &.footer-light {
    border-top: solid 1px rgba(255, 255, 255, .025);
    color: #a2a2a2;

    a {
      color: #a2a2a2;
      font-weight: 400;
      text-decoration: none !important;

      &:hover {
        color: $color;
      }
    }

    #form_subscribe {
      input[type="text"] {
        width: 80%;
        float: left;
        border-radius: 30px 0 0 30px;
        color: #ffffff;
        background: rgba(255, 255, 255, .1);
        border: solid 1px #333333;
        border-right-color: rgb(51, 51, 51);
        border-right-style: solid;
        border-right-width: 1px;
        border-right: none;
        padding: 6px 12px;
        margin-bottom: 0;
        box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
      }

      #btn-subscribe i {
        text-align: center;
        font-size: 28px;
        float: left;
        width: 20%;
        background: $color;
        color: #ffffff;
        display: table-cell;
        padding: 5px 0 5px 0;
        border-radius: 0 30px 30px 0;
      }
    }

    .subfooter {
      margin-top: 40px;
      padding: 20px 0 20px 0;
      border-top: solid 1px rgba(255, 255, 255, .1);

      span {
        cursor: pointer;

        img {
          margin-right: 30px;
          width: 25px;

          &.f-logo.d-1 {
            display: inline-block;
          }

          &.f-logo.d-3 {
            display: none;
          }

          &.f-logo.d-4 {
            display: none;
          }
        }

        &.copy {
          cursor: default;
          margin: 0;
        }
      }

      .social-icons {
        display: inline-block;

        span {
          color: #595d69;

          i {
            text-shadow: none;
            color: black;
            background: #0d6efd;
            padding: 12px 10px 8px 10px;
            width: 34px;
            height: 34px;
            text-align: center;
            font-size: 16px;
            border-radius: 5px;
            margin: 0 5px 0 5px;
            transition: .3s;
          }

          &:hover i {
            color: $black;
            background: $white;
            transition: .3s;
          }
        }
      }
    }
  }

  .widget {
    margin-bottom: 30px;
    padding-bottom: 30px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 5px 0;
      }
    }

    h5 {
      margin-bottom: 20px;
    }
  }
}

.greyscheme #scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;

  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }

  &.show {
    bottom: 15px;
    transition: all 0.3s ease;
  }

  div {
    font-size: .8rem;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    background: $color;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }

    i {
      font-style: normal;

      &:before {
        font-family: "FontAwesome";
        font-size: 18px;
        content: "\f106";
        color: #fff !important;
        position: relative;
      }
    }
  }
}

/** portofolio **/
.greyscheme .AppContainer {
  color: $white;
  position: relative;
  z-index: 99;
  height: 100%;
  width: 100%;
  background: none;

  .gallery-container {
    .react-photo-gallery--gallery {}
  }
}

.greyscheme .react-photo-gallery--gallery {
  margin-top: 15px;
}

.greyscheme .lightbox-portal {
  position: relative;
  z-index: 999;

  h4 {
    font-weight: 700;
  }
}

.greyscheme .btnLright {
  color: $white;
  font-size: 21px !important;
  right: 10px !important;
}

.greyscheme .btnLleft {
  color: $white;
  font-size: 21px !important;
  left: 10px !important;
}

.greyscheme .closeL {
  font-size: 21px !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

.greyscheme .ConMainGimg {
  border-radius: 5px;
  overflow: hidden;
}

.greyscheme .MainGimg {
  transform: scale(1);
  transition: .7s;

  .overlayCap {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    width: 100%;
    bottom: 15px;
    font-weight: 500;
    color: $white;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .7s;
    outline: none;
    z-index: 1;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $color_more;
      z-index: 0;
      opacity: .8;
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: .7s;
    z-index: 2;

    .overlayCap {
      opacity: 1;
    }
  }
}

/** contact **/
.greyscheme .form-side {
  border-radius: 4px;
}

.greyscheme .text-side {
  padding: 15px 0;
  margin-top: 15px;

  .address {
    padding-top: 15px;
    line-height: 1.6;

    .heading {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 1px;
    }

    .list {
      position: relative;
      max-width: 320px;
      display: block;
      padding: 5px 0 15px;
      padding-left: 40px;

      i {
        position: absolute;
        top: 5px;
        left: 5px;
        font-size: 1.3rem;
        display: inline-block;
      }

      a {
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          color: $color;
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.greyscheme .formcontact {
  display: block;
  width: 100%;

  label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    font-size: .9rem;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 15px;
  }

  textarea {
    font-size: .9rem;
    width: 100%;
    height: 195px;
    padding: 8px 10px;
    border: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 15px;
  }

  .msg-error {
    font-size: .87rem;
    opacity: .8;
    padding: 5px 0;
  }

  button {
    position: relative;
    overflow: hidden;
    font-size: 11pt;
    color: $white;
    background: none;
    border: 2px solid $color;
    border-radius: 0;
    padding: 8px 50px;
    margin-top: 15px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    z-index: 0;

    span {
      color: $white;
      position: relative;
      z-index: 3;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to left, $color, $color_more);
      opacity: 0;
      z-index: 1;
      transition: all 0.3s ease;
    }

    &:hover::before {
      opacity: 1;
      transition: all 0.3s ease;
    }

    &:hover {
      .shine {
        -webkit-animation: sheen 1s alternate;
        animation: sheen 1s alternate;
      }
    }
  }
}

.greyscheme #success,
.greyscheme #failed {
  &.show {
    display: block !important;
    margin-bottom: 15px;
  }

  &.hide {
    display: none;
  }
}

.greyscheme #buttonsent.show {
  pointer-events: none;
}

.greyscheme #buttonsent {
  width: max-content;
  padding: 8px 30px;
}

.greyscheme .padding40.box-rounded.mb30 {
  padding: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, .05);

  &.text-light {
    color: $white;
    background: rgba(255, 255, 255, .05);
    margin-bottom: 0;

    h3 {
      color: $white;
    }

    .btn {
      color: $white;
    }
  }
}

.greyscheme address {
  margin-top: 20px;
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;

  &.s1 span {
    display: block;
    padding: 0;
    font-size: 15px;
    line-height: 1.7em;
    margin-bottom: 5px;

    i {
      color: $color;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 15px;
      font-size: 15px;
    }

    .btn {
      display: inline-block;
      border: 0;
      color: $color;
      padding: 0;
      margin: 0;

      &:hover {
        background: none;
        padding: 0;
      }

      &:before {
        content: unset;
      }
    }
  }
}

/*** news ***/
.greyscheme .post-content {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.greyscheme .post-image {
  position: relative;

  img {
    width: 100%;
    margin-bottom: 0px;
  }
}

.greyscheme .post-text {
  padding-top: 20px;

  .p-tagline,
  .p-title {
    background: $color_more;
    color: $white;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 3px;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 10px;
    padding: 7px 10px 6px 10px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .p-date {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    font-weight: 500;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      color: $white;
      text-decoration: none;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.greyscheme .pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.greyscheme .pagination li .a {
  font-size: 14px;
  color: $white;
  border: solid 1px rgba(255, 255, 255, .1);
  border-right: none;
  background: none;
  padding: 15px 20px 15px 20px;
  border-radius: 0;
  cursor: pointer;
}

.greyscheme .dark-scheme .pagination li .a {
  border-color: rgba(255, 255, 255, 0);
  background: #21273e;
}

.greyscheme .pagination li:last-child .a {
  border-right: solid 1px rgba(255, 255, 255, .1);
}

.greyscheme .dark-scheme .pagination li:last-child .a {
  border-right-color: rgba(255, 255, 255, .1);
}

.greyscheme .pagination>.active>.a {
  color: #ffffff;
  background: $color;
}

/*** create file ***/
.greyscheme .d-create-file {
  padding: 50px;
  border-radius: 10px;
  border: dashed 3px #dddddd;
  text-align: center;

  #get_file {
    margin: 0 auto;
  }

  .browse {
    position: relative;
    width: max-content;
    height: auto;
    margin: 0 auto;
    cursor: pointer;

    #upload_file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover {
      .btn-main {
        box-shadow: 2px 2px 20px 0px #FF343F;
        transition: all 0.3s ease;
      }
    }
  }
}

/*** activity ***/
.greyscheme .filter__l {
  font-weight: bold;
}

.greyscheme .filter__r {
  float: right;
  cursor: pointer;
}

.greyscheme .activity-filter {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    padding: 8px 10px;
    border: solid 1px rgba(255, 255, 255, .1);
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    width: 48%;
    font-weight: bold;
    cursor: pointer;

    i {
      height: 32px;
      width: 32px;
      padding: 9px 0;
      text-align: center;
      border-radius: 100%;
      background: rgba(255, 255, 255, .01);
      color: $color;
      margin-right: 10px;
    }
  }
}

.greyscheme .activity-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 20px;
    padding-right: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 100px;
    background: rgba(255, 255, 255, .025);
    border-top: 1px solid rgba(255, 255, 255, .1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

    img {
      width: 80px;
      position: absolute;
      border-radius: 100%;
    }

    &.act_sale {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f291";
      }
    }

    &.act_like {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f004";
      }
    }

    &.act_offer {
      img {
        border-radius: 5px;
      }

      &::after {
        content: "\f0e3";
      }
    }

    .act_list_text {
      padding-left: 100px;

      .act_list_date {
        display: block;
      }

      h4 {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    &:after {
      content: "\f00c";
      font-family: "FontAwesome";
      margin-right: 15px;
      float: right;
      position: absolute;
      right: 20px;
      top: 30%;
      font-size: 26px;
      color: $color;
      background: rgba(255, 255, 255, .025);
      border-top: 1px solid rgba(255, 255, 255, .1);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
      height: 48px;
      width: 48px;
      padding: 6px 0;
      text-align: center;
      border-radius: 100%;
    }
  }
}

.greyscheme #sale.active,
.greyscheme #follow.active,
.greyscheme #offer.active,
.greyscheme #like.active {
  background: $color !important;
  color: $white;
}

/*** feature box ***/
.greyscheme .feature-box .inner {
  position: relative;
  overflow: hidden;
  padding: 40px;
  border-radius: 3px;
}

.greyscheme .feature-box.s1 .inner {
  background: #212428;
}

.greyscheme .feature-box.s1:hover .inner i {
  background: none;
  color: #303030;
}

.greyscheme .text-light .feature-box .inner {
  color: #fff;
}

.greyscheme .feature-box i {
  font-size: 40px;
  margin-bottom: 20px;
}

.greyscheme .feature-box.left i {
  position: absolute;
}

.greyscheme .feature-box.left .text {
  padding-left: 70px;
}

.greyscheme .feature-box.center {
  text-align: center;
}

.greyscheme .feature-box i.circle,
.greyscheme .feature-box i.square {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

.greyscheme .feature-box i.square {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

.greyscheme .feature-box i.circle {
  display: inline-block;
  padding: 20px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  position: relative;
  z-index: 2;
}

.greyscheme i.font60 {
  font-size: 60px;
}

.greyscheme .feature-box i.wm {
  font-size: 800px;
  position: absolute;
  top: 0%;
  width: 100%;
  left: 50%;
  transition: 2s ease;

  &:hover {
    transition: 2s ease;
  }
}

.greyscheme .feature-box:hover .inner i.wm {
  transform: scale(1.2);
  color: rgba(0, 0, 0, .05);
  z-index: 0;
}

.greyscheme .feature-box:hover .inner i.circle {
  -webkit-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.3);
}

.greyscheme .feature-box.style-3 {
  position: relative;
  overflow: hidden !important;
}

.greyscheme .feature-box.style-3 i {
  display: block;
  text-align: center;
  font-size: 44px;
  color: $color ;
  background: transparent;
  width: 56px;
  height: 56px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.greyscheme .feature-box.style-3 i.wm {
  position: absolute;
  font-size: 240px;
  background: none;
  width: auto;
  height: auto;
  color: rgba(252, 52, 63, .1);
  right: 0;
  top: 30%;
}

.greyscheme .feature-box.style-3:hover i.wm {
  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);
}

.greyscheme .feature-box.style-4 i {
  position: relative;
  z-index: 2;
  padding: 10px;
  min-width: 60px;
}

.greyscheme .feature-box.style-4 .wm {
  display: block;
  position: absolute;
  font-size: 160px;
  background: none;
  width: 100%;
  height: auto;
  right: 0;
  top: 120px;
  z-index: 2;
  font-weight: bold;
}

.greyscheme .feature-box.style-4 .text {
  margin-top: -50px;
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 30px;
  padding-top: 50px;
}

.greyscheme .feature-box.f-boxed {
  overflow: none;
  padding: 50px;
  background: #212428;
  border-radius: 6px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  transition: .7s;

  &:hover {
    background: #212428;
    transition: .7s;
  }
}

.greyscheme .text-light .feature-box.f-boxed {
  background: rgba(0, 0, 0, .1);
}

.greyscheme .feature-box.f-boxed:hover {
  color: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.greyscheme .feature-box.f-boxed:hover h4 {
  color: #ffffff;
}

.greyscheme .feature-box i.i-circle {
  border-radius: 60px;
}

.greyscheme .feature-box i.i-boxed {
  border-radius: 5px;
}

.greyscheme .feature-box-small-icon.center {
  text-align: center;
}

.greyscheme .dark .feature-box-small-icon .inner:hover>i {
  color: #fff;
}

.greyscheme .feature-box-small-icon .text {
  padding-left: 80px;
}

.greyscheme .feature-box-small-icon.center .text {
  padding-left: 0;
  display: block;
}

.greyscheme .feature-box-small-icon i {
  text-shadow: none;
  font-size: 40px;
  width: 68px;
  height: 68px;
  text-align: center;
  position: absolute;
  text-align: center;
}

.greyscheme .feature-box-small-icon i.hover {
  color: #333;
}

.greyscheme .feature-box-small-icon .border {
  height: 2px;
  width: 30px;
  background: #ccc;
  display: block;
  margin-top: 20px;
  margin-left: 85px;
}

.greyscheme .feature-box-small-icon .btn {
  margin-top: 10px;
}

.greyscheme .feature-box-small-icon.center i {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.greyscheme .feature-box-small-icon.center .fs1 {
  position: inherit;
  float: none;
  display: inline-block;
  margin-bottom: 20px;
  border: none;
  font-size: 60px;
  background: none;
  padding: 0px;
}

.greyscheme .feature-box-small-icon i.dark {
  background-color: #1A1C26;
}

.greyscheme .feature-box-small-icon h4 {
  line-height: normal;
  margin-bottom: 5px;
}

.greyscheme .feature-box-small-icon.no-bg h2 {
  padding-top: 12px;
}

.greyscheme .feature-box-small-icon .number {
  font-size: 40px;
  text-align: center;
  position: absolute;
  width: 70px;
  margin-top: -5px;
}

.greyscheme .feature-box-small-icon .number.circle {
  border-bottom: solid 2px rgba(255, 255, 255, .3);
  padding: 20px;
  width: 70px;
  height: 70px;
}

.greyscheme .feature-box-small-icon.style-2 .number {
  font-size: 32px;
  color: #fff;
  padding-top: 22px;
  background: #253545;
  width: 70px;
  height: 70px;
}

.greyscheme .feature-box-small-icon.no-bg .inner,
.greyscheme .feature-box-small-icon.no-bg .inner:hover {
  padding: 0;
  background: none;
}

.greyscheme .f-hover {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  outline: none;
}

.greyscheme .icon_wallet::before {
  content: "\e100";
  font-family: 'ElegantIcons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  transition: .7s;
}

.greyscheme .keyUnder {
  width: 70px;
  border-bottom: 2px solid $color ;
  transition: .7s;
  margin-bottom: 1rem;
}

/*** column nft ***/
.greyscheme .nft_coll {
  padding-bottom: 10px;
  border-top: solid 1px rgba(255, 255, 255.1);
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  margin: 10px;

  img {
    transition: .7s;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: .7s;
    }
  }
}

.greyscheme .nft_wrap {
  position: relative;
  width: 100%;
  height: 200%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  border-bottom: 2px solid #212428;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
}

.greyscheme .nft_coll_pp {
  width: 60px;
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 10px;
  position: relative;

  span {
    cursor: pointer;
  }
}

.greyscheme .nft_coll_pp img {
  width: 60px;
  height: auto;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: solid 5px #ffffff;
  background: #ffffff;
}

.greyscheme .nft_coll_pp i {
  font-size: 10px;
  color: #ffffff;
  background: $color;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 3px;
  position: absolute;
  bottom: 4px;
  right: 5px;
}

.greyscheme .nft_coll_info {
  text-align: center;
  padding-bottom: 10px;

  span {
    cursor: pointer;
  }
}

.greyscheme .nft_coll h4 {
  font-size: 16px;
  margin-bottom: 0px;
  color: $black;
}

.greyscheme .nft_coll span {
  font-size: 14px;
}

.greyscheme .nft_coll p {
  margin-top: 10px;
}

.greyscheme .nft_coll_by {
  font-weight: bold;
}

.greyscheme .nft {
  width: 100%;

  .slick-prev {
    left: -12px;
    background: #212428;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      content: "\f053" !important;
      font-size: 1rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $color;
      background: #212428;
      border: 1px solid rgba(255, 255, 255, .1);
      border-radius: 45px;
      display: block;
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    &:hover {
      background: $white !important;
      box-shadow: 0px 0px 10px 0px $color;
    }
  }

  .slick-next {
    right: -12px;
    background: #212428;
    border-radius: 45px;
    display: block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    transition: all 0.3s ease;

    &::before {
      content: "\f054" !important;
      font-size: 1rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $color;
      background: #212428;
      border: 1px solid rgba(255, 255, 255, .1);
      border-radius: 45px;
      display: block;
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    &:hover {
      background: $white !important;
      box-shadow: 0px 0px 10px 0px $color;
    }
  }

  &:hover {

    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: .98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {

      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: .9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: .75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

.greyscheme .logo__item {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  margin: 10px;
  border-top: solid 1px rgba(255, 255, 255, .1);
  border: solid 1px rgba(255, 255, 255, .1);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  margin-bottom: 25px;
  transition: .7s;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 30px 0px rgba(10, 10, 10, 0.1);
    transition: .7s;
  }
}

.greyscheme .logo__item img {
  width: 100%;
  height: auto !important;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.greyscheme .nft__item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  padding: 20px;
  margin: 10px;
  border-top: solid 1px rgba(255, 255, 255, .1);
  border: solid 1px rgba(255, 255, 255, .1);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, .025);
  transition: .7s;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 30px 0px rgba(10, 10, 10, 0.1);
    transition: .7s;
  }
}

.greyscheme .nft__item_info {
  cursor: pointer;
  line-height: 28px;
  margin-top: 1rem;
}

.greyscheme .nft__item h4 {
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 5px;
}

.greyscheme .nft__item_price {
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: white
}

.greyscheme .nft__item_price span {
  margin-left: 10px;
  color: $white;
  font-weight: 700;
}

.greyscheme .nft__item_action {
  font-size: 14px;

  div {
    font-weight: bold;
    color: #0d6efd;
  }

  :hover {
    color: $color;
  }
}

.greyscheme .nft__item_like {
  font-size: 14px;

  span {
    color: $white;
    margin-left: 5px;
    font-size: 14px;
  }

  &:hover i {
    color: pink;
    transition: 0.5s;
  }
}

.greyscheme .buyButton {
  width: 100%;
  background-color: black;
  color: white;
  padding: 0.5rem 0;
  border-radius: 15px;
  margin-bottom: 1rem;
}

/*** countdown ***/
.greyscheme .de_countdown {
  position: relative;
  font-size: .8rem;
  position: absolute;
  right: 20px;
  background: #212428;
  padding: 6px 10px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border: solid 2px $color;
  z-index: 1;
  color: $white;

  .Clock-days {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-hours {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-minutes {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .Clock-seconds {
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }
}

.greyscheme .author_list_pp {
  cursor: pointer;
  width: 50px;
  background: $color;
  margin-left: 10px;
  margin-top: -3px;
  border-radius: 100% !important;
  z-index: 1;
  transition: .3s;

  &:hover img {
    padding: 3px;
    -webkit-box-shadow: 0px 0px 0px 2px $color;
    transition: .3s;
  }
}

.greyscheme .author_list_pp img {
  width: 100%;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  position: relative;
  z-index: 1;
}

.greyscheme .author_list_pp i {
  color: #ffffff;
  background: $color;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  border: 1px solid black;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 2;
}

.greyscheme .nft__item_wrap {
  width: 100%;
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  // margin-top: 60px;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
}

.greyscheme .nft__item_wrap span {
  display: block;
  width: 100%;
}

.greyscheme .nft__item img {
  width: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

/*** author list ***/
.greyscheme .author_list {
  column-count: 4;
  column-gap: 20px;

  li {
    margin-bottom: 30px;
  }

  .author_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -3px;
    cursor: pointer;
    z-index: 10;

    &:hover {
      img {
        padding: 1px;
        background: $gray;
        box-shadow: 0px 0px 0px 4px rgba(131, 100, 226, 1);
        transition: .2s ease;
      }
    }

    img {
      transition: .2s ease;
    }
  }

  .author_list_info {
    font-weight: bold;
    padding-left: 70px;

    span {
      cursor: pointer;
      color: $white;
    }

    .bot {
      color: $color;
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
}

.blurBack {
  padding: 90px 0;
  padding-top: 120px;
  //backdrop-filter: blur(2px) grayscale(30%);
  // backdrop-filter:  blur(2px) contrast(140%) hue-rotate(315deg);
}

.bg-home-one {
  background-color: #181b25 !important;
}

.bg-home-two {
  background-color: #212428 !important;
}

/*** icon box ***/
.greyscheme .icon-box {
  display: block;
  text-align: center;
  border: solid 1px #dddddd;
  border-radius: 6px !important;
  padding: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
}

.greyscheme .icon-box.style-2 {
  border: none;
  background: #212428;
  text-align: left;
  border-bottom: 5px solid white;
  height: 100%;
}

.greyscheme .text-light .icon-box {
  border: solid 2px rgba(255, 255, 255, .1);
}

.greyscheme .icon-box i {
  display: block;
  font-size: 60px;
  margin-bottom: 30px;
  transition: .3s;
  color: $color;
}

.greyscheme .icon-box h3 {
  font-size: 30px;
}

.greyscheme .icon-box span {
  display: block;
  color: white;
  font-weight: 500;
  font-size: 17px;
  margin-top: 5px;
  line-height: 1.4em;
}

.greyscheme .dark-scheme .icon-box span {
  color: #ffffff;
}

.greyscheme .text-light .icon-box span {
  color: #ffffff;
}

.greyscheme .icon-box:hover {
  color: white;
  background: $color_more;
  // -webkit-box-shadow: 5px 5px 15px 0px $color_more;
  // -moz-box-shadow: 5px 5px 15px 0px $color_more;
  // box-shadow: 5px 5px 15px 0px $color_more;
  transition: .3s;

  span,
  i,
  h3 {
    color: #f2f2f2;
  }
}

.verticalLine {
  width: 50%;
  height: 80px;
  border-right: 3px solid $color;
  margin-top: 30px;
}

.greyscheme #tsparticles {
  position: absolute;
  width: 100%;
  height: 80%;
  overflow: hidden;

  canvas {
    position: relative !important;
  }
}

.greyscheme .relative {
  position: relative;
}

/*** carousel ***/
.greyscheme .d-carousel {
  position: relative;
  display: flex;
  align-items: center;
}

.greyscheme .nft_pic {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin: 0 10px 10px;
  transition: .7s;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s;
  }

  &:hover {
    img {
      transition: 3s;
      transform: scale(1.2);
    }
  }
}

.greyscheme .nft_pic_info {
  position: absolute;
  background: rgba(0, 0, 0, .2);
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 1;
  font-size: 28px;
  color: #ffffff;
}

.greyscheme .nft_pic_title {
  display: block;
  font-weight: bold;
}

.greyscheme .nft_pic_by {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, .6);
}

.greyscheme .nft-big {
  width: 100%;

  .slick-prev {
    left: 10px;
    display: block;
    width: 40px;
    height: 60px;
    overflow: hidden;
    line-height: 60px;
    background: none !important;
    border-radius: 0;
    z-index: 1;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f053" !important;
      font-size: .9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-left: 20px;
      border-radius: 60px;
      background: $white;
      left: -25px;
      opacity: 1;
    }
  }

  .slick-next {
    right: 9px;
    display: block;
    width: 40px;
    height: 60px;
    line-height: 60px;
    background: none !important;
    border-radius: 0;
    overflow: hidden;
    transition: all 0.3s ease;

    &::before {
      display: block;
      content: "\f054" !important;
      font-size: .9rem;
      font-family: FontAwesome !important;
      font-style: normal;
      font-weight: normal;
      color: $black;
      position: relative;
      line-height: 60px;
      width: 60px;
      height: 60px;
      padding-right: 20px;
      border-radius: 60px;
      background: $white;
      right: -4px;
      opacity: 1;
    }
  }

  &:hover {

    .slick-prev,
    .slick-next {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  .blockquote {
    font-size: .98rem;
    text-align: left;
    margin: 15px 30px 30px;
    display: flex;

    .fa {
      font-size: 1.3rem;
      display: inline-block;
      margin: 5px 15px 0 0;
      color: $white;
      height: max-content;
    }

    p {

      line-height: 1.8;
      display: inline-block;
    }

    .bytesti {
      font-size: .9rem;
      font-weight: 400;
    }
  }

  .slick-dots {
    bottom: -40px;

    li.slick-active button::before {
      opacity: .75;
      color: $white;
    }

    li button::before {
      color: $white;
    }
  }
}

/*** filter ***/
.greyscheme .items_filter {
  width: 100%;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: -14px;
  display: flex;
  align-items: baseline;

  .dropdownSelect {
    display: inline-block;
    width: 200px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    z-index: 2;

    .css-1okebmr-indicatorSeparator {
      background: none;
    }

    &.one {
      z-index: 9;
    }

    &.two {
      z-index: 8;
    }

    &.three {
      z-index: 7;
    }
  }

  &.centerEl {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

.greyscheme #form_quick_search {
  flex: 1 0;
  display: inline-block;
  margin-right: 0px;
  position: relative;
  top: 14px;

  input[type="text"] {
    padding: 7px 12px;
    width: 90%;
    float: left;
    color: $white;
    border-radius: 5px 0 0 5px;
    border: solid 1px rgba(255, 255, 255, .1);
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-right: none;
    background: none;
    box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
    font-size: 16px;
  }

  #btn-submit {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;

    i {
      text-align: center;
      font-size: 16px;
      float: left;
      width: 60px;
      background: $color;
      color: #ffffff;
      display: table-cell;
      padding: 12px 0;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
    }
  }
}

.greyscheme {
  .css-wenqhu-control {
    background-color: #212428;
    border: 1px solid rgba(255, 255, 255, .1) !important;

    &>div>div {
      color: $white;
    }
  }
}

/*** colection ***/
.greyscheme #profile_banner {
  min-height: 360px;
}

.greyscheme .d_coll {
  position: relative;
}

.greyscheme .d_profile {
  margin-bottom: 40px;
}

.greyscheme .profile_avatar {
  display: flex;
}

.greyscheme .d_coll .profile_avatar {
  display: block;
  text-align: center;
}

.greyscheme .profile_avatar img,
.greyscheme .profile_name {
  display: flex;
  align-items: center;

  .profile_username {
    font-size: 16px;
    display: block;
    color: $color;
  }
}

.greyscheme .d_coll .profile_avatar img,
.greyscheme .d_coll .profile_name {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.greyscheme .profile_avatar img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 150px;
  height: auto;
}

.greyscheme .d_coll .profile_avatar img {
  display: inline-block;
  margin-top: -100px;
  margin-bottom: 30px;
}

.greyscheme .profile_avatar i {
  color: #ffffff;
  background: $color;
  margin: 110px 0 0 110px;
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.greyscheme .d_coll .profile_avatar i {
  margin-top: -30px;
}

.greyscheme .d_coll .d_profile_img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.greyscheme .profile_name {
  margin-left: 20px;
}

.greyscheme .profile_name h4 {
  font-size: 24px;
  line-height: 1.3em;
}

.greyscheme .profile_wallet {
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  float: left;
  color: #727272;
}

.greyscheme .d_coll .profile_wallet {
  display: inline-block;
  float: none;
}

.greyscheme #btn_copy {
  position: absolute;
  font-size: 12px;
  padding: 4px 10px;
  line-height: 1em;
  border: solid 1px #dddddd;
  display: inline-block;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: none;

  &:hover {
    color: $white;
    background: $color;
    border: 0;
  }
}

.greyscheme .de_nav {
  overflow: hidden;
  padding-left: 0;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: bold;

  &.text-left {
    text-align: left;
    margin: 10px;
  }

  li {
    float: none;
    display: inline-block;
    margin-right: 5px;

    span {
      position: relative;
      padding: 10px 20px;
      margin-right: 5px;
      display: block;
      background: none;
      text-decoration: none;
      color: $gray;
      border: 1px solid rgba(255, 255, 255, .1);
      font-size: 15px;
      cursor: poInter;
      -webkit-border-radius: 3px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.active span {
      color: $white;
      background: none;
    }
  }
}

.greyscheme .profile_follower {
  margin-right: 20px;
  font-weight: 600;
}

/*** item details ***/
.greyscheme .item_info {
  padding-left: 10px;

  .de_countdown {
    position: relative;
    display: inline-block;
    margin: 0;
    margin-bottom: 0px;
    margin-left: 0px;
    right: 0;
    border: none;
    padding: 0;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .item_info_counts {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;

    >div {
      margin-right: 10px;
      font-weight: 400;
      padding: 2px 10px;
      font-size: 14px;
      text-align: center;
      min-width: 80px;
      background: rgba(255, 255, 255, .025);
      border-top: solid 1px rgba(255, 255, 255, .1);
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;

      i {
        margin-right: 5px;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  h6 {
    margin-bottom: 15px;
    font-size: 12px;
  }

  .item_author .author_list_info {
    padding-top: 10px;
    padding-left: 70px;
    color: $white;
    font-weight: bold;
  }

  .de_nav {
    text-align: left;
    margin-bottom: 30px;
  }
}

.greyscheme .p_list {
  margin-bottom: 30px;
}

.greyscheme .p_list_pp {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: auto;
  margin-left: 0px;
  margin-top: -3px;
  z-index: 10;

  img {
    width: 100%;
    border-radius: 100% !important;
  }

  i {
    color: #ffffff;
    background: $color;
    font-size: 10px;
    padding: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
  }
}

.greyscheme .p_list_info {
  font-weight: 400;
  padding-left: 70px;

  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2em;
  }

  b {
    color: $white;
  }
}

img.img-rounded {
  border-radius: 3px;
}

/*** wallet ***/
.greyscheme .box-url {
  position: relative;
  padding: 30px;
  color: #a2a2a2;
  background: rgba(255, 255, 255, .03);
  border: 1px solid rgba(255, 255, 255, .1);
  display: block;
  border-radius: 6px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
  transition: .3s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: .3s;
  }

  .box-url-label {
    font-weight: bold;
    position: absolute;
    right: 30px;
    color: $white;
    padding: 2px 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
}

.mb30 {
  margin-bottom: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

/*** login ***/
.greyscheme .field-set label {
  color: #606060;
  font-weight: 500;
}

.greyscheme .form-control {
  padding: 8px;
  margin-bottom: 20px;
  border: none;
  border: solid 1px rgba(255, 255, 255, .1);
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: $white;
}

.greyscheme .list.s3 {
  display: inline;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    list-style: none;
    margin-right: 20px;

    span {
      color: $color;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}

.greyscheme .align-items-center {
  align-items: center !important;
}

.greyscheme .box-login {
  padding: 40px;
  background: #212428;
  border-radius: 5px;

  p {
    color: $white !important;

    span {
      color: $color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .btn-main {
    width: 100%;
  }

  .list.s3 {
    justify-content: left;
    font-size: 1rem;

    li {
      display: inline;
      margin-right: 20px;
      letter-spacing: normal;
      justify-content: left;
    }
  }
}

/*** all icon ***/
.greyscheme .demo-icon-wrap-s2 {
  margin-bottom: 30px;

  span {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color_more;
    background: rgba(131, 100, 226, .1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }
}

.greyscheme .demo-icon-wrap {
  margin-bottom: 30px;

  i {
    text-align: center;
    font-size: 20px;
    margin-right: 20px;
    position: relative;
    color: $color_more;
    background: rgba(131, 100, 226, .1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    padding: 15px 0;
  }

  .text-grey {
    display: none;
  }
}

/*** element ***/
.greyscheme .card-header {
  padding: 1rem 1.25rem;

  .btn {
    width: 100%;
    height: 100%;
    text-align: left;
    color: $general;
    padding: 0;
    margin: 0;
    border: 0;

    &:before {
      content: unset;
    }

    &:focus {
      box-shadow: unset;
    }
  }
}

.greyscheme .card-body {
  padding: 1rem 1.25rem;
}

.greyscheme .sequence {
  .mb30 {
    margin-bottom: 30px;
  }

  .pricing-s1 {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    background: rgba(255, 255, 255, .025);
    background-size: auto;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);

    .top {
      padding: 30px;
      text-align: center;

      h2 {
        color: $color_more;
      }
    }

    .mid {
      padding: 40px;
      text-align: center;
      background: $color_more;

      .m.opt-1 {
        font-size: 48px;
        font-weight: 500;
      }
    }

    .bottom {
      border-top: 0px;
      text-align: center;

      ul {
        display: block;
        list-style: none;
        list-style-type: none;
        list-style-type: none;
        margin: 30px 0;
        padding: 0px;
        text-align: left;
        overflow: hidden;

        li {
          padding: 10px 30px;
          color: #505050;

          i {
            margin-right: 10px;
            color: $color_more;
          }
        }
      }
    }

    .action {
      text-align: center;
      padding: 40px 0;
      border-top: solid 1px rgba(255, 255, 255, .1);

      .btn-main {
        margin: 0 auto;
      }
    }
  }
}

.greyscheme .skill-bar {
  margin-bottom: 40px;

  &.style-2 {
    .de-progress {
      background: rgba(0, 0, 0, .1);
      background-size: auto;
      width: 100%;
      height: 8px;
      background: #ddd;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;

      .progress-bar {
        background: $color_more;
        height: 8px;
        box-shadow: none;
      }
    }
  }
}

.greyscheme #tabs1 {
  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;
  }
}

.greyscheme #tabs2 {
  .greyscheme .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .greyscheme .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

.greyscheme #tabs3 {
  display: flex;
  justify-content: space-between;

  .nav {
    display: inline-block;
    margin-right: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

.greyscheme #tabs4 {
  display: flex;
  flex-direction: row-reverse;

  .nav {
    display: inline-block;
    margin-left: 15px;
  }

  .nav-tabs {
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 30px;

    .nav-link {
      &:hover {
        border: 0;
      }
    }
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: $white;
    background: $color_more;
    border-radius: 4px;
    border: 0;
  }
}

.greyscheme .card {
  background: rgba(255, 255, 255, .025);
}

.greyscheme .nav-link {
  color: $white;

  &:hover {
    color: $color_more;
  }
}

/*** filter option ***/
.greyscheme .item_filter_group {
  border: solid 1px rgba(255, 255, 255, .075);
  margin-bottom: 25px;
  padding: 30px;
  border-radius: 12px;

  .de_checkbox {
    position: relative;
    display: block;
    padding-left: 25px;

    input[type="checkbox"] {
      display: none;
      appearance: none;
      border: none;
      outline: none;
    }
  }

  .de_form input[type="checkbox"]:checked+label {
    border: none;
  }

  .de_form input[type="checkbox"]+label::before {
    position: absolute;
    content: '';
    display: inline-block;
    font-family: "FontAwesome";
    margin-right: 10px;
    border: solid 2px rgba(255, 255, 255, .2);
    width: 16px;
    height: 16px;
    margin-top: 5px;
    left: 0;
    font-size: 11px;
    padding: 1px 3px 0 3px;
    line-height: 15px;
    border-radius: 4px;
  }

  .de_form input[type="checkbox"]:checked+label::before {
    content: "\f00c";
    color: #ffffff;
    border: rgba(0, 0, 0, 0);
    background: $color;
  }
}

/*** table ***/
.greyscheme .de-table.table-rank {
  color: #ffffff;
  display: table;
  margin-bottom: 0;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #dee2e6;

  thead {
    border-style: hidden !important;
    padding-bottom: 15px;
  }

  tr {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;

    &.spacer-single {
      border-bottom: 0 !important;
    }
  }

  th {
    padding: 30px 0;
  }

  td {
    padding: 30px 0;
  }

  .coll_list_pp {
    position: absolute;
    display: inline-block;
    width: 50px;
    height: auto;
    margin-left: 10px;
    margin-top: -12px;
    z-index: 10;
    left: 0;

    img {
      width: 100%;
      border-radius: 100% !important;
      position: relative;
      z-index: 1;
    }

    i {
      color: #ffffff;
      background: $color;
      font-size: 10px;
      padding: 3px;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      z-index: 2;
    }
  }

  tbody th {
    position: relative;
    padding-left: 75px;
  }

  .d-plus {
    color: #34c77b;
  }

  .d-min {
    color: #eb5757;
  }
}

.greyscheme .pagination {
  margin: 0 auto;
  font-weight: 500;
  border-radius: 3px;

  li {
    span {
      border: solid 1px rgba(255, 255, 255, .1);
      padding: 15px 20px 15px 20px;
      color: $white;
      cursor: pointer;
    }
  }

  li.active {
    span {
      border-color: $color;
      border-right: none;
      background: $color;
    }
  }
}

.greyscheme #form_sb {
  .col {
    display: flex;
  }

  input[type="text"] {
    padding: 21px 12px 21px 12px;
    width: 85%;
    height: 30px;
    float: left;
    margin-bottom: 0;
    border-radius: 8px 0 0 8px;
    border: none;
    background: rgba(255, 255, 255, .7);
  }

  #btn-submit {
    border: none;
    background: none;
    margin: 0;
    padding: 0;

    i {
      text-align: center;
      font-size: 30px;
      float: left;
      width: 100%;
      color: #ffffff;
      background: $color;
      display: table-cell;
      padding: 5px 40px 7px 40px;
      border-radius: 0 8px 8px 0;
    }
  }
}

.greyscheme .has_offers {
  .through {
    text-decoration: line-through;
  }
}

.greyscheme .icontype {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: .8rem;
  opacity: .2;
}

/* checkout */
.greyscheme .checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 999;

  .btn-close {
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9991;
    color: $white;
    background: $color;
    border-radius: 50px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    opacity: 1;
    text-align: center;
  }

  .maincheckout {
    position: relative;
    border-radius: 8px;
    background: $white;
    padding: 30px;
    width: 100%;
    max-width: 500px;
    height: max-content;
    margin: 30px 0 0;

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 5px;

      h3 {
        color: $black;
      }

      p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
      }

      .subtotal {
        color: $black;
        font-size: 15px;
      }
    }

    .detailcheckout {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .listcheckout {
        width: 100%;
        display: flex;
        align-items: left;
        flex-direction: column;

        h6 {
          color: $black;
        }

        .thumb {
          width: 90px;
        }

        .description {
          padding: 15px;

          h3,
          p {
            margin-bottom: 0;
          }
        }

        input {
          margin: 10px 0 15px;
          border: solid 1px rgba(0, 0, 0, 0.1);
        }
      }

      .price {
        font-weight: 600;
      }
    }

    .agrement {
      display: flex;
      align-items: center;

      label {
        padding-left: 5px;
      }
    }

    .btn-main {
      width: 100%;
      margin: 30px auto;
      margin-bottom: 15px !important;
    }

    .bold {
      color: $black;
      font-weight: bold;
    }
  }
}

.greyscheme .nft_attr {
  display: block;
  padding: 10px;
  text-align: center;
  background: rgba(252, 52, 63, 0.1);
  border: solid 1px rgba(0, 0, 0, .15);
  border-radius: 5px;
  margin-bottom: 8px;
  text-decoration: none;
  cursor: pointer;

  h5 {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 15px;
    font-weight: 700;
    color: $white;
    margin-bottom: 0;
  }

  span {
    font-size: 13px;
    color: $white;
  }
}

/* blog */
/* ================================================== */
.greyscheme .blog-list {
  margin: 0;
  padding: 0;
}

.greyscheme blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left-color: currentcolor;
  border-left-style: none;
  border-left-width: medium;
  border-left: solid 3px #333333;
  border-left-color: rgb(51, 51, 51);
  padding-left: 30px;
  color: #606060;
}

/* blog list */
.greyscheme .blog-list>div {
  list-style: none;
  line-height: 1.7em;
  margin-top: 0px;
  border-bottom: solid 1px rgba(255, 255, 255, .1);
  margin-bottom: 20px;
}

.greyscheme .blog-list img {
  margin-bottom: 20px;
}

.greyscheme .de_light .blog-list li {
  border-bottom: solid 1px #ddd;
}

.greyscheme .blog-list h4 {
  margin-top: 20px;
}

.greyscheme .blog-list h4 a {
  color: #fff;
}

.greyscheme .de_light .blog-list h4 a {
  color: #222222;
}

.greyscheme .de_light .text-light .blog-list h4 a {
  color: #fff;
}

.greyscheme .blog-list .btn-more {
  padding: 3px 25px 3px 25px;
  color: #111;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  float: right;
}

.greyscheme .blog-list .btn-more:hover {
  background: #fff;
}

.greyscheme .de_light .blog-list .btn-more:hover {
  background: #222;
  color: #fff;
}

.greyscheme .blog-list .post-text {
  padding-left: 80px;
}

.greyscheme .bloglist .post-text a.btn-main {
  display: inline-block;
  margin-top: 0px;
  padding: 5px 25px;
}

.greyscheme .blog-list .blog-slider {
  margin-bottom: -60px;
}

.greyscheme .blog-list .date-box {
  position: absolute;
  text-align: center;
  text-shadow: none;
}

.greyscheme .blog-list .date-box .day,
.greyscheme .blog-list .date-box .month {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  z-index: 100;
}

.greyscheme .de_light .blog-list .date-box .month {
  color: #222222;
}

.greyscheme .blog-list .date-box {
  text-align: center;
  background: #f6f6f6;
  padding: 10px;
}

.greyscheme .blog-list .date-box .month {
  font-size: 14px;
}

.greyscheme .blog-list .date-box .day {
  font-family: var(--body-font);
  color: #222222;
  font-weight: bold;
  font-size: 28px;
}

.greyscheme .blog-list .date-box .month {
  font-family: var(--body-font);
  color: #fff;
  font-size: 13px;
  letter-spacing: 3px;
}

.greyscheme .de_light .blog-list .date-box .month {
  border-bottom-color: #ddd;
}

.greyscheme .de_light .text-light .blog-list .date-box .month {
  color: #fff;
}

.greyscheme .blog-snippet li {
  border-bottom: none;
  margin-bottom: 0;
}

.greyscheme #blog-carousel .item.item {
  width: 100%;
}

/* blog comment */
.greyscheme #blog-comment {
  margin-top: 40px;
}

.greyscheme #blog-comment h5 {
  margin-bottom: 10px;
}

.greyscheme #blog-comment ul,
.greyscheme #blog-comment li {
  list-style: none;
  margin-left: -40px;
  padding-left: 0;
}

.greyscheme #blog-comment li {
  min-height: 70px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #eee;
}

.greyscheme .dark-scheme #blog-comment li {
  border-bottom-color: rgba(255, 255, 255, .1);
}

.greyscheme #blog-comment li .avatar {
  position: absolute;
  margin-top: 5px;
}

.greyscheme #blog-comment .comment {
  margin-left: 85px;
}

.greyscheme #blog-comment li {
  min-height: 70px;
  font-size: 14px;
  line-height: 1.6em;
}

.greyscheme #blog-comment li li {
  margin-left: 55px;
  padding-bottom: 0px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  border-bottom: none;
}

.greyscheme .dark-scheme #blog-comment li li {
  border-top-color: rgba(255, 255, 255, .1);
}

.greyscheme #blog-comment li li .avatar {
  position: absolute;
}

.greyscheme #blog-comment li li .comment {}

.greyscheme .comment-info {
  margin-left: 85px;
  margin-bottom: 5px;
}

.greyscheme .comment-info span {}

.greyscheme .comment-info .c_name {
  display: block;
  font-weight: 700;
  color: #555555;
}

.greyscheme .dark-scheme .comment-info .c_name {
  color: #ffffff;
}

.greyscheme .comment-info .c_reply {
  padding-left: 20px;
  margin-left: 20px;
  border-left: solid 1px #ddd;
  font-size: 12px;
}

.greyscheme .dark-scheme .comment-info .c_reply {
  border-left-color: rgba(255, 255, 255, .2);
}

.greyscheme .comment-info .c_date {
  font-size: 12px;
}

.greyscheme .blog-list h4 {
  color: #333;
}

.greyscheme .blog-list .post-wrapper {}

.greyscheme .blog-read h4 {
  color: #333;
  letter-spacing: normal;
  font-size: 24px;
  text-transform: none;
}

.greyscheme .blog-carousel ul,
.greyscheme .blog-carousel li {
  margin: 0;
  list-style: none;
}

.greyscheme .blog-carousel h4 {
  margin-bottom: 0px;
}

.greyscheme .blog-carousel p {
  margin-bottom: 10px;
}

.greyscheme .blog-carousel .post-date {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 11px;
  color: #888;
}

.greyscheme .post-content {
  position: relative;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.greyscheme .post-content p {
  margin-bottom: 20px;
}

.greyscheme .post-text {
  padding-top: 20px;
}

.greyscheme .post-text h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.greyscheme .post-text h4 a {
  color: #1A1C26;
  text-decoration: none;
  font-size: 22px;
  font-weight: bold;
}

.greyscheme .dark-scheme .post-text h4 a {
  color: #ffffff;
}

.greyscheme .post-text .tags {
  font-size: 10px;
  text-transform: uppercase;
}

.greyscheme .p-tagline {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
}

.greyscheme .p-tagline,
.greyscheme .p-title {
  font-family: var(--body-font);
  background: rgba(var(--primary-color-rgb), .2);
  border-radius: 3px;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 10px;
  padding: 7px 10px 6px 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.greyscheme .p-tagline,
.greyscheme .p-title.invert {
  background: var(--secondary-color);
}

.greyscheme .p-title {
  font-size: 12px;
  margin-bottom: 20px;
}

.greyscheme .post-text .p-date {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 500;
}

.greyscheme .post-info {
  border-top: solid 1px #eeeeee;
  padding-top: 15px;
}

.greyscheme .blog-read img {
  margin-bottom: 20px;
}

.greyscheme .blog-read .img-fullwidth {
  width: 100%;
  height: auto;
}

.greyscheme .blog-read .post-text {
  padding: 0;
}

.greyscheme .post-date,
.greyscheme .post-comment,
.greyscheme .post-like,
.greyscheme .post-by,
.greyscheme .post-author {
  margin-right: 20px;
  font-size: 13px;
  color: #999;
  font-family: var(--body-font);
}

.greyscheme .dark-scheme .post-date,
.greyscheme .dark-scheme .post-comment,
.greyscheme .dark-scheme .post-like,
.greyscheme .dark-scheme .post-by,
.greyscheme .dark-scheme .post-author {
  color: #ffffff;
}

.greyscheme .post-author {
  color: #777;
}

.greyscheme .post-date:before,
.greyscheme .post-comment:before,
.greyscheme .post-like:before,
.greyscheme .post-by:before {
  font-family: FontAwesome;
  padding-right: 10px;
  font-size: 11px;
}

.greyscheme .post-comment:before {
  content: "\f0e5";
}

.greyscheme .post-like:before {
  content: "\f08a";
}

.greyscheme .post-author:before {
  content: "By: ";
}

/* blog comment form */
.greyscheme #comment-form-wrapper {

  .error,
  .success {
    display: none;
  }
}

.greyscheme #commentform label {
  display: block;
}

.greyscheme #commentform input {
  width: 290px;
}

.greyscheme #commentform input:focus {
  border: solid 1px #999;
  background: #fff;
}

.greyscheme #commentform textarea {
  width: 97%;
  padding: 5px;
  height: 150px;
  color: #333;
}

.greyscheme #commentform textarea:focus {
  border: solid 1px #999;
  background: #fff;
}

.greyscheme #commentform input.btn {
  width: auto;
}


.greyscheme .post-meta {
  margin: 0px 0 10px 0px;
  border: solid 1px #eee;
  border-left: none;
  border-right: none;
  border-left: none;
  font-size: 11px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.greyscheme .post-meta a {
  color: #888;
}

.greyscheme .post-meta span {
  display: table-cell;
  padding: 10px 0 10px 0;
  text-align: center;
}

.greyscheme .post-meta span i {
  float: none;
}

.greyscheme .post-image img {
  width: 100%;
  margin-bottom: 0px;
}

.greyscheme .blog-slide {
  padding: 0;
  margin: 0;
}

.greyscheme .blog-item {
  margin-bottom: 60px;
}

.greyscheme .blog-item img.preview {
  width: 100%;
  margin-bottom: 30px;
}

.greyscheme .post-image {
  position: relative;
}

.greyscheme .post-image .post-info {
  position: absolute;
  bottom: 0;
  padding: 0;
  border: none;
  width: 100%;
}

.greyscheme .post-image .post-info .inner {
  margin: 0 30px 0 30px;
  padding: 3px 20px;
  border-radius: 10px 10px 0 0;
}

.greyscheme .post-image .post-date,
.greyscheme .post-image .post-author {
  color: #ffffff;
}

.greyscheme .widget {
  margin-bottom: 30px;
  padding-bottom: 30px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .small-border {
    height: 2px;
    width: 30px;
    margin: 0px 0 20px 0;
    display: block;
  }

  h4 {
    font-size: 18px;
    margin-top: 0;
    letter-spacing: normal;
  }
}

.greyscheme .widget-post {
  li {
    border-bottom: solid 1px #f2f2f2;
    padding-bottom: 8px;
    margin-bottom: 8px;

    a {
      padding-left: 60px;
      display: block;
      text-decoration: none;
      color: #606060;
    }
  }

  .date {
    background: $color_more;
    color: #fff;
    display: inline-block;
    padding: 5px;
    float: left;
    font-size: 12px;
  }
}

.greyscheme .widget_tags {
  li {
    text-shadow: none;
    display: inline-block;
    margin: 5px 6px;

    a {
      font-size: 12px;
      text-decoration: none;
      margin-bottom: 5px;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      color: #fff !important;
      background: $color_more;
      padding: 6px 12px 6px 12px;
    }
  }
}


/*** media all ***/
@media only screen and (max-width: 1399px) {
  .logoWidth {
    min-width: 450px;
  }
}

@media only screen and (max-width: 1199px) {
  .greyscheme .item-dropdown {
    position: relative;
    width: 100%;
    inset: unset;
    padding-top: 10px;
    border: 0;
    background: none;
    box-shadow: unset;

    .dropdown {
      a {
        color: $white !important;
        padding: 10px 10px 10px 0px;
        width: 100%;
        text-align: left;
        left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .greyscheme .navbar {
    top: 0;

    &.white a {
      color: $white;
    }
  }

  .greyscheme #myHeader .container {
    max-width: unset !important;
  }
}

@media only screen and (max-width: 1024px) {
  .greyscheme .m-10-hor {
    padding-left: 2%;
    padding-right: 2%;
  }

  .greyscheme .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  footer.footer-light .subfooter span.copy {
    display: block;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 768px) {
  .greyscheme .breadcumb .mainbreadcumb .list {
    justify-content: left;
    text-align: left;
    margin-bottom: 30px;
  }

  .greyscheme .previousButton,
  .greyscheme .nextButton {
    opacity: 1 !important;
  }

  .greyscheme .xs-hide {
    display: none;
  }

  .greyscheme .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .greyscheme .author_list {
    column-count: 1;
    column-gap: 20px;
  }

  .greyscheme #form_quick_search {
    top: 0;
    width: 100%;

    .col {
      padding-right: 0;
    }
  }

  .greyscheme #form_quick_search input[type="text"] {
    width: 80%;
  }

  .greyscheme #form_quick_search #btn-submit {
    width: 20%;
  }

  .greyscheme #form_quick_search #btn-submit i {
    width: 100%;
  }

  .greyscheme .items_filter .dropdownSelect {
    margin-right: 0;
    width: 96%;
  }

  .greyscheme .item_info {
    margin-top: 30px;
  }

  .greyscheme .profile_avatar {
    display: block;
  }

  .greyscheme .d_profile {
    display: block;

    .de-flex-col {
      display: block;
    }
  }

  .greyscheme .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .greyscheme .profile_follow {
    margin-top: 50px;
    width: 100%;
  }

  .greyscheme .nft__item {
    margin: 0 0 30px 0;
  }

  .greyscheme .de_nav.text-left {
    margin-left: 0;
  }

}

@media only screen and (max-width: 600px) {
  .logoWidth {
    min-width: 360px;
  }
}

@media only screen and (max-width: 420px) {}

@media only screen and (max-width: 378px) {}

/*********************
@ Dilshad sir CSS :: start
*********************/

header {
  height: 100px;
  background: #ffffff;
}

.cd-dropdown-wrapper {
  display: inline-block;
  position: relative;
  height: 40px;
  margin: 30px 0 0 5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd-dropdown-trigger {
  display: block;
  position: relative;
  padding: 0 36px 0 20px;
  line-height: 40px;
  background-color: #111433;
  color: #ffffff;
  border-radius: 3px;
}

.no-touch .cd-dropdown-trigger:hover {
  background-color: #171b46;
}

.cd-dropdown-trigger::before,
.cd-dropdown-trigger::after {
  /* this is the arrow inside the dropdown trigger*/
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  -moz-transition: width 0.3s, -moz-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
}

.cd-dropdown-trigger::before {
  right: 22px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cd-dropdown-trigger::after {
  right: 17px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (min-width: 1024px) {
  .cd-dropdown-trigger {
    font-size: 1.5rem;
  }

  .cd-dropdown-trigger.dropdown-is-active {
    background-color: #3f8654;
  }

  .no-touch .cd-dropdown-trigger.dropdown-is-active:hover {
    background-color: #47975f;
  }

  .cd-dropdown-trigger.dropdown-is-active::before,
  .cd-dropdown-trigger.dropdown-is-active::after {
    /* transform the arrow into a 'X' */
    width: 14px;
  }

  .cd-dropdown-trigger.dropdown-is-active::before {
    /* transform the arrow into a 'X' */
    -webkit-transform: translateX(5px) rotate(-45deg);
    -moz-transform: translateX(5px) rotate(-45deg);
    -ms-transform: translateX(5px) rotate(-45deg);
    -o-transform: translateX(5px) rotate(-45deg);
    transform: translateX(5px) rotate(-45deg);
  }

  .cd-dropdown-trigger.dropdown-is-active::after {
    /* transform the arrow into a 'X' */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a {
  height: 50px;
  line-height: 50px;
}

@media only screen and (min-width: 768px) {

  .cd-dropdown h2,
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    height: 60px;
    line-height: 60px;
  }
}

@media only screen and (min-width: 1024px) {

  .cd-dropdown h2,
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    height: 50px;
    line-height: 50px;
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a,
.cd-dropdown-content .cd-divider {
  padding: 0 20px;
}

.cd-dropdown {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111433;
  color: #ffffff;
  visibility: hidden;
  /* Force Hardware Acceleration */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
  transition: transform 0.5s 0s, visibility 0s 0.5s;
}

.cd-dropdown h2 {
  /* dropdown title - mobile version only */
  position: relative;
  z-index: 1;
  color: #585a70;
  background-color: #111433;
  border-bottom: 1px solid #242643;
}

.cd-dropdown .cd-close {
  /* 'X' close icon - mobile version only */
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}

.cd-dropdown .cd-close::after,
.cd-dropdown .cd-close::before {
  /* this is the 'X' icon */
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 20px;
  width: 2px;
  background-color: #ffffff;
}

.cd-dropdown .cd-close::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.cd-dropdown .cd-close::before {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.cd-dropdown.dropdown-is-active {
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
  transition: transform 0.5s 0s, visibility 0s 0s;
}

@media only screen and (min-width: 768px) {
  .cd-dropdown .cd-close {
    top: 5px;
  }
}

@media only screen and (min-width: 1024px) {
  .cd-dropdown {
    position: absolute;
    top: calc(100% - 2px);
    /* reset style*/
    height: auto;
    width: auto;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    background-color: #ffffff;
    color: #111433;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
  }

  .open-to-left .cd-dropdown {
    /* use the .open-to-left class if you want to open the dropdown on the left */
    right: 0;
    left: auto;
  }

  .cd-dropdown h2,
  .cd-dropdown .cd-close {
    /* on desktop - hide title and 'X' */
    display: none;
  }

  .cd-dropdown.dropdown-is-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 0.3s 0s, visibility 0.3s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0.3s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
  }
}

.cd-dropdown-content,
.cd-dropdown-content ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  padding-top: 50px;
}

.cd-dropdown-content a,
.cd-dropdown-content ul a {
  display: block;
  color: #ffffff;
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-width: 1px;
  border-color: #242643;
  border-style: solid;
}

.cd-dropdown-content li:first-of-type>a,
.cd-dropdown-content ul li:first-of-type>a {
  border-top-width: 0;
}

.cd-dropdown-content li:last-of-type>a,
.cd-dropdown-content ul li:last-of-type>a {
  border-bottom-width: 1px;
}

.cd-dropdown-content .cd-divider,
.cd-dropdown-content ul .cd-divider {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #0b0e23;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #585a70;
  text-transform: uppercase;
}

.cd-dropdown-content .cd-divider+li>a,
.cd-dropdown-content ul .cd-divider+li>a {
  border-top-width: 0;
}

.cd-dropdown-content a,
.cd-dropdown-content .cd-search,
.cd-dropdown-content .cd-divider,
.cd-dropdown-content ul a,
.cd-dropdown-content ul .cd-search,
.cd-dropdown-content ul .cd-divider {
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.cd-dropdown-content.is-hidden,
.cd-dropdown-content ul.is-hidden {
  /* push the secondary dropdown items to the right */
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.cd-dropdown-content.is-hidden>li>a,
.cd-dropdown-content.is-hidden>li>.cd-search,
.cd-dropdown-content.is-hidden>.cd-divider,
.cd-dropdown-content.move-out>li>a,
.cd-dropdown-content.move-out>li>.cd-search,
.cd-dropdown-content.move-out>.cd-divider,
.cd-dropdown-content ul.is-hidden>li>a,
.cd-dropdown-content ul.is-hidden>li>.cd-search,
.cd-dropdown-content ul.is-hidden>.cd-divider,
.cd-dropdown-content ul.move-out>li>a,
.cd-dropdown-content ul.move-out>li>.cd-search,
.cd-dropdown-content ul.move-out>.cd-divider {
  /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
  opacity: 0;
}

.cd-dropdown-content.move-out>li>a,
.cd-dropdown-content.move-out>li>.cd-search,
.cd-dropdown-content.move-out>.cd-divider,
.cd-dropdown-content ul.move-out>li>a,
.cd-dropdown-content ul.move-out>li>.cd-search,
.cd-dropdown-content ul.move-out>.cd-divider {
  /* push the dropdown items to the left when secondary dropdown slides in */
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.dropdown-is-active .cd-dropdown-content,
.dropdown-is-active .cd-dropdown-content ul {
  -webkit-overflow-scrolling: touch;
}

@media only screen and (min-width: 768px) {

  .cd-dropdown-content,
  .cd-dropdown-content ul {
    padding-top: 60px;
  }

  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1024px) {

  .cd-dropdown-content,
  .cd-dropdown-content ul {
    padding-top: 0;
    overflow: visible;
  }

  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    color: #111433;
    height: 50px;
    line-height: 50px;
    font-size: 1.5rem;
    border-color: #ebebeb;
  }

  .cd-dropdown-content .cd-divider,
  .cd-dropdown-content ul .cd-divider {
    background-color: transparent;
    color: #b3b3b3;
    border-top: 1px solid #ebebeb;
  }

  .cd-dropdown-content .cd-divider+li>a,
  .cd-dropdown-content ul .cd-divider+li>a {
    border-top-width: 1px;
  }

  .cd-dropdown-content.is-hidden>li>a,
  .cd-dropdown-content.is-hidden>li>.cd-search,
  .cd-dropdown-content.is-hidden>.cd-divider,
  .cd-dropdown-content.move-out>li>a,
  .cd-dropdown-content.move-out>li>.cd-search,
  .cd-dropdown-content.move-out>.cd-divider,
  .cd-dropdown-content ul.is-hidden>li>a,
  .cd-dropdown-content ul.is-hidden>li>.cd-search,
  .cd-dropdown-content ul.is-hidden>.cd-divider,
  .cd-dropdown-content ul.move-out>li>a,
  .cd-dropdown-content ul.move-out>li>.cd-search,
  .cd-dropdown-content ul.move-out>.cd-divider {
    /* reset mobile style */
    opacity: 1;
  }
}

.cd-dropdown-content .see-all a {
  /* different style for the See all links */
  color: #3f8654;
}

.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item,
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
  /* items with picture (or icon) and title */
  height: 80px;
  line-height: 80px;
}

.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item h3,
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
  padding-left: 90px;
}

.cd-dropdown-content .cd-dropdown-gallery img {
  position: absolute;
  display: block;
  height: 40px;
  width: auto;
  left: 20px;
  top: 50%;
  margin-top: -20px;
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
  padding-left: 75px;
  position: relative;
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
  color: #111433;
  font-size: 1.3rem;
  /* hide description on small devices */
  display: none;
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
  /* item icon */
  content: '';
  display: block;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-1::before {
  // background-image: url("../assets/img/nucleo-icon-1.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-2::before {
  // background-image: url("../assets/img/nucleo-icon-2.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-3::before {
  // background-image: url("../assets/img/nucleo-icon-3.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-4::before {
  // background-image: url("../assets/img/nucleo-icon-4.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-5::before {
  // background-image: url("../assets/img/nucleo-icon-5.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-6::before {
  // background-image: url("../assets/img/nucleo-icon-6.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-7::before {
  // background-image: url("../assets/img/nucleo-icon-7.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-8::before {
  // background-image: url("../assets/img/nucleo-icon-8.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-9::before {
  // background-image: url("../assets/img/nucleo-icon-9.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-10::before {
  // background-image: url("../assets/img/nucleo-icon-10.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-11::before {
  // background-image: url("../assets/img/nucleo-icon-11.svg");
}

.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-12::before {
  // background-image: url("../assets/img/nucleo-icon-12.svg");
}

@media only screen and (min-width: 1024px) {
  .cd-dropdown-content {
    /* reset mobile style */
    position: static;
    height: auto;
    width: 280px;
  }

  .cd-dropdown-content>li:last-of-type a {
    border-bottom: none;
  }

  .no-touch .cd-dropdown-content>li:not(.has-children) a:hover {
    color: #3f8654;
  }

  .cd-dropdown-content.move-out>li>a,
  .cd-dropdown-content.move-out>li>.cd-search,
  .cd-dropdown-content.move-out>.cd-divider {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  .cd-dropdown-content .cd-secondary-dropdown,
  .cd-dropdown-content .cd-dropdown-gallery,
  .cd-dropdown-content .cd-dropdown-icons {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    left: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  }

  .cd-dropdown-content .cd-secondary-dropdown::after,
  .cd-dropdown-content .cd-dropdown-gallery::after,
  .cd-dropdown-content .cd-dropdown-icons::after {
    clear: both;
    content: "";
    display: table;
  }

  .open-to-left .cd-dropdown-content .cd-secondary-dropdown,
  .open-to-left .cd-dropdown-content .cd-dropdown-gallery,
  .open-to-left .cd-dropdown-content .cd-dropdown-icons {
    /* use the .open-to-left class if you want to open the dropdown on the left */
    left: auto;
    right: 100%;
  }

  .cd-dropdown-content .cd-secondary-dropdown.is-hidden,
  .cd-dropdown-content .cd-dropdown-gallery.is-hidden,
  .cd-dropdown-content .cd-dropdown-icons.is-hidden {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  .cd-dropdown-content .cd-secondary-dropdown.fade-in,
  .cd-dropdown-content .cd-dropdown-gallery.fade-in,
  .cd-dropdown-content .cd-dropdown-icons.fade-in {
    /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
    -webkit-animation: cd-fade-in 0.2s;
    -moz-animation: cd-fade-in 0.2s;
    animation: cd-fade-in 0.2s;
  }

  .cd-dropdown-content .cd-secondary-dropdown.fade-out,
  .cd-dropdown-content .cd-dropdown-gallery.fade-out,
  .cd-dropdown-content .cd-dropdown-icons.fade-out {
    /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
    -webkit-animation: cd-fade-out 0.2s;
    -moz-animation: cd-fade-out 0.2s;
    animation: cd-fade-out 0.2s;
  }

  .cd-dropdown-content .cd-secondary-dropdown>.go-back,
  .cd-dropdown-content .cd-dropdown-gallery>.go-back,
  .cd-dropdown-content .cd-dropdown-icons>.go-back {
    display: none;
  }

  .cd-dropdown-content .cd-secondary-dropdown>.see-all,
  .cd-dropdown-content .cd-dropdown-gallery>.see-all,
  .cd-dropdown-content .cd-dropdown-icons>.see-all {
    position: absolute;
    bottom: 20px;
    height: 45px;
    text-align: center;
  }

  .cd-dropdown-content .cd-secondary-dropdown>.see-all a,
  .cd-dropdown-content .cd-dropdown-gallery>.see-all a,
  .cd-dropdown-content .cd-dropdown-icons>.see-all a {
    margin: 0;
    height: 100%;
    line-height: 45px;
    background: #ebebeb;
    pointer-events: auto;
    -webkit-transition: color 0.2s, background-color 0.2s;
    -moz-transition: color 0.2s, background-color 0.2s;
    transition: color 0.2s, background-color 0.2s;
  }

  .no-touch .cd-dropdown-content .cd-secondary-dropdown>.see-all a:hover,
  .no-touch .cd-dropdown-content .cd-dropdown-gallery>.see-all a:hover,
  .no-touch .cd-dropdown-content .cd-dropdown-icons>.see-all a:hover {
    color: #ffffff;
    background-color: #111433;
  }

  .cd-dropdown-content .cd-secondary-dropdown .cd-dropdown-item,
  .cd-dropdown-content .cd-secondary-dropdown a,
  .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item,
  .cd-dropdown-content .cd-dropdown-gallery a,
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item,
  .cd-dropdown-content .cd-dropdown-icons a {
    border: none;
  }

  .cd-dropdown-content .cd-dropdown-gallery,
  .cd-dropdown-content .cd-dropdown-icons {
    padding: 20px 30px 100px;
  }

  .cd-dropdown-content .cd-dropdown-gallery>.see-all,
  .cd-dropdown-content .cd-dropdown-icons>.see-all {
    width: calc(100% - 60px);
  }

  .cd-dropdown-content .cd-dropdown-icons>li,
  .cd-dropdown-content .cd-secondary-dropdown>li {
    width: 50%;
    float: left;
  }

  .cd-dropdown-content .cd-secondary-dropdown {
    overflow: hidden;
    width: 550px;
    padding-bottom: 65px;
  }

  .cd-dropdown-content .cd-secondary-dropdown::before {
    /* this is the separation line in the middle of the .cd-secondary-dropdown element */
    position: absolute;
    content: '';
    top: 290px;
    left: 15px;
    height: 1px;
    width: 520px;
    background-color: #ebebeb;
  }

  .cd-dropdown-content .cd-secondary-dropdown>li>a {
    color: #3f8654;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 30px;
    height: 30px;
    pointer-events: none;
  }

  .cd-dropdown-content .cd-secondary-dropdown>li>a::after,
  .cd-dropdown-content .cd-secondary-dropdown>li>a::before {
    /* hide the arrow */
    display: none;
  }

  .cd-dropdown-content .cd-secondary-dropdown.move-out>li>a {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  .cd-dropdown-content .cd-secondary-dropdown>li {
    margin: 20px 0;
    border-right-width: 1px;
    border-color: #ebebeb;
    border-style: solid;
    padding: 0 30px;
    height: 250px;
  }

  .cd-dropdown-content .cd-secondary-dropdown>li:nth-of-type(2n) {
    border-right-width: 0;
  }

  .cd-dropdown-content .cd-secondary-dropdown>li>ul {
    /* reset mobile style */
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    position: relative;
    height: auto;
  }

  .cd-dropdown-content .cd-secondary-dropdown>li>ul>.go-back {
    display: none;
  }

  .cd-dropdown-content .cd-secondary-dropdown a {
    line-height: 25px;
    height: 25px;
    font-size: 1.3rem;
    padding-left: 0;
  }

  .no-touch .cd-dropdown-content .cd-secondary-dropdown a:hover {
    color: #3f8654;
  }

  .cd-dropdown-content .cd-secondary-dropdown ul {
    padding-bottom: 25px;
    overflow: hidden;
    height: auto;
  }

  .cd-dropdown-content .cd-secondary-dropdown .go-back a {
    padding-left: 20px;
    color: transparent;
  }

  .no-touch .cd-dropdown-content .cd-secondary-dropdown .go-back a:hover {
    color: transparent;
  }

  .cd-dropdown-content .cd-secondary-dropdown .go-back a::before,
  .cd-dropdown-content .cd-secondary-dropdown .go-back a::after {
    left: 0;
  }

  .cd-dropdown-content .cd-secondary-dropdown .see-all {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .cd-dropdown-content .cd-dropdown-gallery {
    width: 600px;
    padding-bottom: 100px;
  }

  .cd-dropdown-content .cd-dropdown-gallery>li {
    width: 48%;
    float: left;
    margin-right: 4%;
  }

  .cd-dropdown-content .cd-dropdown-gallery>li:nth-of-type(2n) {
    margin-right: 0;
  }

  .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
    padding: 0;
    height: auto;
    line-height: normal;
    color: #3f8654;
    margin-bottom: 2em;
  }

  .cd-dropdown-content .cd-dropdown-gallery>li:nth-last-of-type(2) a,
  .cd-dropdown-content .cd-dropdown-gallery>li:last-of-type a {
    margin-bottom: 0;
  }

  .cd-dropdown-content .cd-dropdown-gallery img {
    position: static;
    height: auto;
    width: 100%;
    margin: 0 0 0.6em;
  }

  .cd-dropdown-content .cd-dropdown-icons {
    width: 600px;
  }

  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    height: 80px;
    line-height: 1.2;
    padding: 24px 0 0 85px;
  }

  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item:hover {
    background: #ebebeb;
  }

  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
    color: #3f8654;
    font-weight: bold;
  }

  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
    display: block;
    font-size: 1.2rem;
  }

  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
    left: 25px;
  }

  .cd-dropdown-content>.has-children>ul {
    visibility: hidden;
  }

  .cd-dropdown-content>.has-children>ul.is-active {
    /* when hover over .cd-dropdown-content items - show subnavigation */
    visibility: visible;
  }

  .cd-dropdown-content>.has-children>.cd-secondary-dropdown.is-active>li>ul {
    /* if .cd-secondary-dropdown is visible - show also subnavigation */
    visibility: visible;
  }

  .cd-dropdown-content>.has-children>a.is-active {
    /* hover effect for .cd-dropdown-content items with subnavigation */
    box-shadow: inset 2px 0 0 #3f8654;
    color: #3f8654;
  }

  .cd-dropdown-content>.has-children>a.is-active::before,
  .cd-dropdown-content>.has-children>a.is-active::after {
    background: #3f8654;
  }

  .open-to-left .cd-dropdown-content>.has-children>a.is-active {
    box-shadow: inset -2px 0 0 #3f8654;
  }
}

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

@-moz-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

@keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

.cd-search input[type="search"] {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #242643;
  color: #ffffff;
  border: none;
  border-radius: 0;
}

.cd-search input[type="search"]::-webkit-input-placeholder {
  color: #ffffff;
}

.cd-search input[type="search"]::-moz-placeholder {
  color: #ffffff;
}

.cd-search input[type="search"]:-moz-placeholder {
  color: #ffffff;
}

.cd-search input[type="search"]:-ms-input-placeholder {
  color: #ffffff;
}

.cd-search input[type="search"]:focus {
  background: #ffffff;
  color: #111433;
  outline: none;
}

.cd-search input[type="search"]:focus::-webkit-input-placeholder {
  color: rgba(17, 20, 51, 0.4);
}

.cd-search input[type="search"]:focus::-moz-placeholder {
  color: rgba(17, 20, 51, 0.4);
}

.cd-search input[type="search"]:focus:-moz-placeholder {
  color: rgba(17, 20, 51, 0.4);
}

.cd-search input[type="search"]:focus:-ms-input-placeholder {
  color: rgba(17, 20, 51, 0.4);
}

@media only screen and (min-width: 1024px) {
  .cd-search input[type="search"] {
    background-color: #ebebeb;
  }

  .cd-search input[type="search"]::-webkit-input-placeholder {
    color: #b3b3b3;
  }

  .cd-search input[type="search"]::-moz-placeholder {
    color: #b3b3b3;
  }

  .cd-search input[type="search"]:-moz-placeholder {
    color: #b3b3b3;
  }

  .cd-search input[type="search"]:-ms-input-placeholder {
    color: #b3b3b3;
  }
}

.has-children>a,
.go-back a {
  position: relative;
}

.has-children>a::before,
.has-children>a::after,
.go-back a::before,
.go-back a::after {
  /* arrow icon in CSS - for element with nested unordered lists */
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 2px;
  width: 10px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.has-children>a::before,
.go-back a::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.has-children>a::after,
.go-back a::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (min-width: 1024px) {

  .has-children>a::before,
  .has-children>a::after,
  .go-back a::before,
  .go-back a::after {
    background: #b3b3b3;
  }
}

.has-children>a {
  padding-right: 40px;
}

.has-children>a::before,
.has-children>a::after {
  /* arrow goes on the right side - children navigation */
  right: 20px;
  -webkit-transform-origin: 9px 50%;
  -moz-transform-origin: 9px 50%;
  -ms-transform-origin: 9px 50%;
  -o-transform-origin: 9px 50%;
  transform-origin: 9px 50%;
}

@media only screen and (min-width: 1024px) {
  .open-to-left .cd-dropdown-content>.has-children>a {
    padding-left: 40px;
    padding-right: 20px;
  }

  .open-to-left .cd-dropdown-content>.has-children>a::before,
  .open-to-left .cd-dropdown-content>.has-children>a::after {
    right: auto;
    left: 20px;
    -webkit-transform-origin: 1px 50%;
    -moz-transform-origin: 1px 50%;
    -ms-transform-origin: 1px 50%;
    -o-transform-origin: 1px 50%;
    transform-origin: 1px 50%;
  }
}

.cd-dropdown-content .go-back a {
  padding-left: 40px;
}

.cd-dropdown-content .go-back a::before,
.cd-dropdown-content .go-back a::after {
  /* arrow goes on the left side - go back button */
  left: 20px;
  -webkit-transform-origin: 1px 50%;
  -moz-transform-origin: 1px 50%;
  -ms-transform-origin: 1px 50%;
  -o-transform-origin: 1px 50%;
  transform-origin: 1px 50%;
}

.cd-main-content {
  background-color: #e6e6e6;
  min-height: calc(100vh - 100px);
  padding: 2em 5%;
  line-height: 2;
}

.no-js .cd-dropdown-wrapper:hover .cd-dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.no-js .cd-dropdown-wrapper .cd-close {
  display: none;
}